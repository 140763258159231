export default {
  fuchsia: {
    color: '#ff00ff',
    name: 'fuchsia',
  },
  teal: {
    color: '#4dc0b5',
    name: 'teal',
  },
}
